<template>
  <b-overlay :show="showOverlay">
    <b-card>
      <b-form
        class="mb-5 "
      >
        <b-row class="align-items-center">
          <b-col md="4">
            <!-- statut -->
            <b-form-group
              :label="$t('COMPANY')"
            >
              <b-form-input
                v-model="filter.selectedCompany"
                name="company"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="2"
          >
            <b-button
              variant="outline-success"
              @click="filterData"
            >
              {{ $t('FILTER_BUTTON') }}
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </b-card>

    <b-card>
      <b-card-header>
        <b-card-title>
          {{ $t('COMPANY_LIST') }}
        </b-card-title>
      </b-card-header>
      <vue-good-table
        :columns="columns"
        :rows="filteredCompany"
        :search-options="{
          enabled: false,
        }"
        :sort-options="{
          enabled: false,
          initialSortBy: {field: 'company', type: 'asc'}
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template
          slot="table-column"
          slot-scope="props"
        >
          {{ $t(props.column.label) }}
        </template>

        <template
          slot="table-row"
          slot-scope="data"
        >
          <span
            v-if="data.column.field === 'action'"
            class="text-nowrap"
          >
            <b-button
              variant="outline-info"
              class="btn-icon"
              @click="$router.push({ path: `/admin/company/${data.row.id}`})"
            >
              <feather-icon
                icon="EyeIcon"
              />
            </b-button>
          </span>

          <span
            v-else-if="data.column.field === 'id'"
            class="text-nowrap"
          >
            {{ data.row.id }}
          </span>

          <span v-else-if="data.column.field === 'createdAt'">
            {{ data.row.createdAt | luxon({ output: "dd/MM/y HH:mm" }) }}
          </span>

          <span v-else-if="data.column.field === 'adress'">
            <span>{{ data.row.adress }} </span><br>
            <span v-if="data.row.adress2">{{ data.row.adress2 }}<br></span>
            <span>{{ data.row.postalCode }} {{ data.row.city }} <br></span>
            <span>{{ data.row.pays }}</span>
          </span>

          <span v-else>
            {{ data.formattedRow[data.column.field] }}
          </span>

        </template>

        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t('PAGINATION_SHOWING') }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5','10','25', '50', '100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> {{ $t('PAGINATION_SHOWING_ELEMENT') }} {{ $t('PAGINATION_TOTAL') }} {{ props.total }} </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </b-overlay>

</template>

<script>
import { VueGoodTable } from 'vue-good-table'

import {
  BOverlay, BRow, BCol, BCard, BCardHeader, BButton, BCardTitle, BForm, BFormGroup, BFormInput, BFormSelect, BPagination,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'

export default {
  components: {
    VueGoodTable,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BButton,
    BCardTitle,
    BFormInput,
    BForm,
    BFormGroup,
    BFormSelect,
    BPagination,
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    allCompanies: {
      type: Array,
      default: () => [],
    },
    showOverlay: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      columns: [
        {
          label: '',
          field: 'action',
        },
        {
          label: 'COMPANY_CODE_CLIENT_NAVISION',
          field: 'navisCode',
          filterable: true,
          sortable: true,
          width: '220px',
        },
        {
          label: 'COMPANY',
          field: 'company',
          filterable: true,
          sortable: true,
          width: '220px',
        },
        {
          label: 'COMPANY_DATE_CREATION',
          field: 'createdAt',
          filterable: true,
          sortable: true,
          width: '240px',
        },
        {
          label: 'COMPANY_PHONE',
          field: 'phone',
          filterable: true,
          sortable: true,
          width: '220px',
        },
        {
          label: 'COMPANY_EMAIL_CONTACT',
          field: 'emailContact',
          filterable: true,
          sortable: true,
          width: '220px',
        },
        {
          label: 'COMPANY_ADRESS',
          field: 'adress',
          filterable: true,
          sortable: true,
          width: '300px',
        },
        {
          label: 'COMPANY_NOMBRE_COLLABORATEUR',
          field: 'collaborateurs',
          filterable: true,
          sortable: true,
          type: 'number',
        },
      ],
      filter: {
        selectedCompany: ' ',
      },
      pageLength: 50,

    }
  },
  computed: {
    companies() {
      return this.$store.getters['companies/getCompanies']
    },
    filteredCompany() {
      return this.$store.getters['companies/filteredCompany']
    },
  },
  methods: {
    filterData() {
      if (this.filter.selectedCompany !== null) {
        this.$store.dispatch('companies/filter', this.filter.selectedCompany)
      }
    },
  },
}
</script>
<style scoped lang="scss">
body.dark-layout .vgt-wrap .vgt-table td span.badge {
  color: #fff;
}
</style>
