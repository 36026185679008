<template>
  <companiesFilter
    :title="$t('ALL_COMPLAINT')"
    :button-new-reclamation="true"
    :display-filter="true"
    :show-overlay="showOverlay"
    :default-status="defaultStatus"
    :all-companies="allCompanies"
  />
</template>

<script>
import companiesFilter from './list/filtreListing.vue'

export default {
  components: {
    companiesFilter,
  },
  data() {
    return {
      allCompanies: [],
      showOverlay: false,
      defaultStatus: { value: 'PROCESSING', label: 'PROCESSING' },
    }
  },
  computed: {
    getCompanies() {
      return this.$store.getters['companies/getCompanies']
    },
  },
  mounted() {
    this.showOverlay = true
    this.$store.dispatch('companies/fetchAll')
      .then(response => {
        this.allCompanies = response
        const condition = []
        condition.status = 'PROCESSING'
        this.showOverlay = false
      }).catch(() => {
        this.showOverlay = false
        this.$router.push('/pages/error-404')
      })
  },
}
</script>
